<template>
  <div>
    <c-table
      ref="table"
      :title="`${hcfFacilityType.hcfFacilityTypeName} 시설현황`"
      :columns="facilityColumns"
      :data="facilityData"
      :gridHeight="gridHeight"
      :editable="editable&&!disabled"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <!-- <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="LBLREMOVE" icon="remove" @btnClicked="deleteHazardChem" />
          <c-btn v-if="editable&&!disabled" label="LBLADD" icon="add" @btnClicked="addHazardChem" />
          <c-btn v-if="editable&&!disabled" label="LBLSAVE" icon="save" @btnClicked="saveHazardChem" />
        </q-btn-group>
      </template> -->
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'facility-status-table-facility-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmFacilityStatusTableId: '',
      }),
    },
    facilityStatusTable: {
      type: Object,
      default: () => ({
        chmFacilityStatusTableId: '',  // 취급시설 현황표 일련번호
        facilityStatusTableName: '',  // 현황표명
        plantCd: '',  // 사업장코드
        permitDate: '',  // 허가일
        companyName: '',  // 회사명
        managerName: '',  // 대표자
        managerEmail: '',  // 대표자 E-MAIL
        phoneNo: '',  // 전화번호
        bizNo: '',  // 사업자번호
        remarks: '',  // 비고
        attrVal1: '',  // 회사id
        attrVal2: '',  // 소재지
        attrVal3: '',  // 시설구분
        regDtStr: '',
        regUserName: '',
        managers: [], // 유해화학물질관리자
        facilities: [], // 시설
        hazardChems: [], // 유해화학물질 취급현황
        equipDevices: [], // 설비 장치
        equipPowers: [], // 설비 동력기계
      }),
    },
    hcfFacilityType: {
      type: Object,
      default: () => ({
        hcfFacilityTypeCd: '',
        hcfFacilityTypeName: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
      },
      hcfFacilityTypeItems: [],
      popupOptions: {
        isFull: false,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let _height = this.contentHeight ? this.contentHeight - 120 : 700
      return _height + 'px';
    },
    facilityData() {
      let _data = [];
      if (this.facilityStatusTable.facilities && this.facilityStatusTable.facilities.length > 0) {
        _data = this.$_.filter(this.facilityStatusTable.facilities, { hcfFacilityTypeCd: this.hcfFacilityType.hcfFacilityTypeCd })
      }
      return _data;
    },
    facilityColumns() {
      let _cols
      if (this.hcfFacilityType.hcfFacilityTypeCd === 'HFT0000001') {
        _cols = [
          {
            name: 'processName',
            field: 'processName',
            label: '단위공정',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'facilityName',
            field: 'facilityName',
            label: '시설명',
            align: 'left',
            style: 'width:100px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'installationPlace',
            field: 'installationPlace',
            label: '설치장소<br/>(실내/실외)',
            align: 'left',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'annualHandling',
            field: 'annualHandling',
            label: '연간취급량<br/>(톤/년)<br/>(허가증상)',
            align: 'right',
            style: 'width:100px',
            sortable: true,
            type: 'cost'
          },
          {
            name: 'installationDate',
            field: 'installationDate',
            label: '설치일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col1',
            field: 'col1',
            label: '최종보수일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'disasterPreventionEquipment',
            field: 'disasterPreventionEquipment',
            label: '방제설비',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: 'LBLREMARK',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
        ]
      } else if (this.hcfFacilityType.hcfFacilityTypeCd === 'HFT0000005' // 실내 저장
        || this.hcfFacilityType.hcfFacilityTypeCd === 'HFT0000015' // 실외 저장
        || this.hcfFacilityType.hcfFacilityTypeCd === 'HFT0000025') {  // 지하 저장
        _cols = [
          {
            name: 'processName',
            field: 'processName',
            label: '단위공정',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'facilityName',
            field: 'facilityName',
            label: '시설명',
            align: 'left',
            style: 'width:100px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'installationPlace',
            field: 'installationPlace',
            label: '설치장소<br/>(실내/실외)',
            align: 'left',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'annualHandling',
            field: 'annualHandling',
            label: '시설용량(㎥)',
            align: 'right',
            style: 'width:100px',
            sortable: true,
            type: 'cost'
          },
          {
            name: 'installationDate',
            field: 'installationDate',
            label: '설치일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col1',
            field: 'col1',
            label: '최종보수일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'disasterPreventionEquipment',
            field: 'disasterPreventionEquipment',
            label: '방제설비',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: 'LBLREMARK',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
        ]
      } else if (this.hcfFacilityType.hcfFacilityTypeCd === 'HFT0000010' // 실내 보관
        || this.hcfFacilityType.hcfFacilityTypeCd === 'HFT0000020') {  // 실외 보관
        _cols = [
          {
            name: 'processName',
            field: 'processName',
            label: '단위공정',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'facilityName',
            field: 'facilityName',
            label: '시설명',
            align: 'left',
            style: 'width:100px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'installationPlace',
            field: 'installationPlace',
            label: '설치장소<br/>(실내/실외)',
            align: 'left',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'annualHandling',
            field: 'annualHandling',
            label: '시설용량(㎥)',
            align: 'right',
            style: 'width:100px',
            sortable: true,
            type: 'cost'
          },
          {
            name: 'installationDate',
            field: 'installationDate',
            label: '설치일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col1',
            field: 'col1',
            label: '최종보수일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'disasterPreventionEquipment',
            field: 'disasterPreventionEquipment',
            label: '방제설비',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: 'LBLREMARK',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
        ]
      } else if (this.hcfFacilityType.hcfFacilityTypeCd === 'HFT0000030') {  // 차량 운송
        _cols = [
          {
            name: 'facilityName',
            field: 'facilityName',
            label: '차량번호',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'col1',
            field: 'col1',
            label: '운송물질',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'modeOfTransport',
            field: 'modeOfTransport',
            label: '운송방식<br/>(탱크로리 등)',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'tankSpecifications',
            field: 'tankSpecifications',
            label: '탱크 사양<br/>(재질 및 용량(㎥, ton) 등)',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
        ]
      } else if (this.hcfFacilityType.hcfFacilityTypeCd === 'HFT0000035') {  // 차량 운반
        _cols = [
          {
            name: 'facilityName',
            field: 'facilityName',
            label: '차량번호',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'col1',
            field: 'col1',
            label: '운반물질',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'transportMethod',
            field: 'transportMethod',
            label: '운반방식<br/>(화물 등)',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'carryingCapacity',
            field: 'carryingCapacity',
            label: '운반 용량<br/>(㎥, ton)',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
        ]
      } else if (this.hcfFacilityType.hcfFacilityTypeCd === 'HFT0000040') {  // 배관이송
        _cols = [
          {
            name: 'facilityName',
            field: 'facilityName',
            label: '시설명',
            align: 'left',
            style: 'width:100px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'distance',
            field: 'distance',
            label: '거리(km)',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col1',
            field: 'col1',
            label: '배관내 이송물질',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'pipeMaterial',
            field: 'pipeMaterial',
            label: '배관재질',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
        ]
      }
      return _cols;
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '시설 상세'; // 시설 상세
      this.popupOptions.param = {
        facilityCd: row ? row.facilityCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/env/hcf/hazardFacilityDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>